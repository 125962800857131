html {
	-webkit-font-smoothing: inherit !important;
}

body {
	font-family: sans-serif;
	text-overflow: ellipsis;
}

html,
body,
#root {
	height: 100%;
}

img {
	width: 100%;
	height: auto;
}

@media only screen and (min-width: 720px) {
	img {
		max-width: 500px;
	}
}

.session-content img {
	width: 100%;
	height: auto;
}

.session-content {
	color: #585858;
	line-height: 30px;
}

.codex-editor__redactor {
	padding-bottom: 100px !important;
}

.session-content strong,
h1,
h2,
h3,
h4,
h5 {
	color: black;
}

.flash-container {
	padding: 10px;
	background-color: #eeeeee;
	text-align: center;
}

.alert {
	padding: 10px;
	text-align: center;
	background-color: white;
}

.content-layout {
	background-color: #ffffff;
}

.content {
	padding: 10px 15px;
}

.updates-container {
	padding: 10px;
	background-color: #dddddd;
	border-bottom: 1px solid #ccc;
	text-align: center;
}

.screen-centered {
	position: fixed;
	top: 50%;
	text-align: center;
	transform: translate(0, -50%);
	width: 100%;
	padding: 0 5%;
}
